<template>
  <div class="">
  <div class="flex justify-center text-white" style="padding-bottom: 20px;"> <img src="https://tbff-assets.ams3.cdn.digitaloceanspaces.com/images/navlb.png"> </div>
      <div class="bg-black" style="padding-bottom: 20px;">
        <video v-show="show_video == 1" ref="videoPlayer1" class="w-full h-full" preload="metadata" playsinline controls="true" controlslist="nodownload" crossorigin="anonymous">
            <source :src="this.video_urls[0]" type="video/mp4">
            <track label="Lyrics" kind="subtitles" srclang="en" :src="this.video_urls[6]">
            <track label="Notes" kind="subtitles" srclang="fr" :src="this.video_urls[7]">
            Your browser does not support the video tag.
        </video>
        <video v-show="show_video == 2" ref="videoPlayer2" class="w-full h-full" preload="metadata" playsinline controls="true" controlslist="nodownload" crossorigin="anonymous">
            <source :src="this.video_urls[1]" type="video/mp4">
            <track label="Lyrics" kind="subtitles" srclang="en" :src="this.video_urls[6]">
            <track label="Notes" kind="subtitles" srclang="fr" :src="this.video_urls[7]">
            Your browser does not support the video tag.
        </video>
        <video v-show="show_video == 3" ref="videoPlayer3" class="w-full h-full" preload="metadata" playsinline controls="true" controlslist="nodownload" crossorigin="anonymous"> 
            <source :src="this.video_urls[2]" type="video/mp4">
            <track label="Lyrics" kind="subtitles" srclang="en" :src="this.video_urls[6]">
            <track label="Notes" kind="subtitles" srclang="fr" :src="this.video_urls[7]">
            Your browser does not support the video tag.
        </video>
        <video v-show="show_video == 4" ref="videoPlayer4" class="w-full h-full" preload="metadata" playsinline controls="true" controlslist="nodownload" crossorigin="anonymous">
            <source :src="this.video_urls[3]" type="video/mp4">
            <track label="Lyrics" kind="subtitles" srclang="en" :src="this.video_urls[6]">
            <track label="Notes" kind="subtitles" srclang="fr" :src="this.video_urls[7]">
            Your browser does not support the video tag.
        </video>
        <video v-show="show_video == 5" ref="videoPlayer5" class="w-full h-full" preload="metadata" playsinline controls="true" controlslist="nodownload" crossorigin="anonymous">
            <source :src="this.video_urls[4]" type="video/mp4">
            <track label="Lyrics" kind="subtitles" srclang="en" :src="this.video_urls[6]">
            <track label="Notes" kind="subtitles" srclang="fr" :src="this.video_urls[7]">
            Your browser does not support the video tag.
        </video>
        <video v-show="show_video == 6" ref="videoPlayer6" class="w-full h-full" preload="metadata" playsinline controls="true" controlslist="nodownload" crossorigin="anonymous">
            <source :src="this.video_urls[5]" type="video/mp4">
            <track label="Lyrics" kind="subtitles" srclang="en" :src="this.video_urls[6]">
            <track label="Notes" kind="subtitles" srclang="fr" :src="this.video_urls[7]">
            Your browser does not support the video tag.
        </video>
      </div>

    <div class="grid grid-row-3 controls justify-items-center p-4 bg-gray-800">
      <div class="space-x-4 space-y-2">
        <button class="" @click="videoRewind(-20)">
          <img height="48" width="48" src="/img/button/Back.svg" />
        </button>
        <button class="" @click="videoPlay()">
          <img height="48" width="48" src="/img/button/Play.svg" />
        </button>
        <button class="" @click="videoPause()">
          <img height="48" width="48" src="/img/button/Pause.svg" />
        </button>
      </div>
      <div class="space-x-4 space-y-2">
        <button class="" @click="video(1)">
          <img height="48" width="48" src="/img/button/Front.svg" />
        </button>
        <button class="" @click="video(2)">
          <img height="48" width="48" src="/img/button/Rear.svg" />
        </button>
        <button class="" @click="video(3)">
          <img height="48" width="48" src="/img/button/Side.svg" />
        </button>
      </div>
        <div class="space-x-4 space-y-2">
        <button class="" @click="video(4)">
          <img height="48" width="48" src="/img/button/FB.svg" />
        </button>
        <button class="" @click="video(5)">
          <img height="48" width="48" src="/img/button/M.svg" />
        </button>
        <button class="" @click="video(6)">
          <img height="48" width="48" src="/img/button/FA.svg" />
        </button>
      </div>
    </div>

  </div>
</template>

<script>
import apiClient from "@/services/API";

export default {
    name: 'videopage',
    data () {
    return {
        show_video: 1,
        currentVideoTime: 0,
        play_status: 0,
        video_urls: [],
  }
},
components: {

},
  methods: {
      videoPlay() {
          const currentVideoId = this.show_video;
          const currentVideoPlayer = this.$refs['videoPlayer' + currentVideoId];
          currentVideoPlayer.play();
          this.play_status = 1;
      },
      videoPause() {
          const currentVideoId = this.show_video;
          const currentVideoPlayer = this.$refs['videoPlayer' + currentVideoId];
          currentVideoPlayer.pause();
          this.play_status = 0;
      },
      videoRewind(param) {
          const currentVideoId = this.show_video;
          const currentVideoPlayer = this.$refs['videoPlayer' + currentVideoId];
          currentVideoPlayer.currentTime += param;
      },
      video(id) {
          const currentVideoId = this.show_video;
          const currentVideoPlayer = this.$refs['videoPlayer' + currentVideoId];
          currentVideoPlayer.pause();
          this.currentVideoTime = currentVideoPlayer.currentTime;
        //console.log(this.currentVideoTime);
          const newVideoPlayer = this.$refs['videoPlayer' + id];
          newVideoPlayer.currentTime = this.currentVideoTime;
          setTimeout(() => {
            this.show_video = id;
            if (this.play_status == 1) {
                newVideoPlayer.play();
            }
          }, 100);
          
      },
      async fetchData() {
        try {
          const url = '/video/' + this.$route.params.folder + '/' + this.$route.params.name
          const response = await apiClient.get(url)
          const results = response.data
          this.video_urls = results
          this.$refs.videoPlayer1.load();
          this.$refs.videoPlayer2.load();
          this.$refs.videoPlayer3.load();
          this.$refs.videoPlayer4.load();
          this.$refs.videoPlayer5.load();
          this.$refs.videoPlayer6.load();
          console.log(url);
          
        } catch (error) {
          if (error.response) {
            // client received an error response (5xx, 4xx)
            console.log("Server Error:", error)
          } else if (error.request) {
            // client never received a response, or request never left
            console.log("Network Error:", error)
          } else {
            console.log("Client Error:", error)
          }
        }
    },
  },
  mounted () {
      this.fetchData();
  }
}
</script>

<style>

#app {
    background-color: black;
}

  svg {
      color: white !important;
  }


    html {
        background-color: black;
    }

    .window {
      background-color: black;
  }

@media screen and (min-height: 640px) and (max-height: 1024px) and (orientation: portrait) {
    video {
        margin-left: 0rem;
        margin-right: 0rem;
        min-width: 100vw;
    }
}

@media screen and (min-width: 640px) and (max-width: 1024px) and (orientation: landscape) {
  #nav {
      display: none;
  }

  .window {
      width: 100vw;
      height: 100vh;
      overflow: hidden;
      padding: 0;
      background-color: black;
  }

  .controls {
      height: 15vh;
      background-color: black;
  }

  video {
      max-height: 85vh;
      min-width: 100vw;
      margin-left: 0;
      margin-right: 0;
  }
}
</style>